body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background: #f5f8fa !important;
  height: 100%;
}

a {
	text-decoration: none;
}

#logo {
	max-width: 200px;
	margin-bottom: 1em;
}

#root{
	height: 100%;
}

#react-root {
	min-height: 100%;
	display: flex;
	flex-direction: column;
}

html{
	height: 100%;
}

.ui.modal {
	margin-top: 0 !important;
	top: 30%;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}